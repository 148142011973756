import React, { useEffect, useState } from "react";
import {  Avatar, Box, Link, Typography, useTheme } from "@mui/material";
import Header from "components/Header";
import { useDispatch, useSelector } from "react-redux";
import { hideLoading, showLoading } from "state/loadingSlicer";
import DialogYesNo from "components/dialog/DialogYesNo";
import { deleteHost, deleteHostForce, getSessionToken, getUri } from "../../hooks/HttpRequests";
import {  useNavigate } from "react-router-dom";
import tm from "components/TranslationManager";
import { stringAvatar } from "components/StyledComponents";
import DialogAddEmployee from "components/dialog/AddEmplyeeDialog";
import GridCellActions from "components/GridCellActions";
import { enqueueSnackbar } from "notistack";
import SadevioDataGridPro from "components/SadevioDataGridPro";


const EmployeeDirectory = ({ route }) => {

  const navigate = useNavigate();

  var isAuth = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [yesNoMessage, setYesNoMessage] = useState("");
  const [yesNoDialogEntity, setYesNoDialogEntity] = useState({});

  const [topSearchFilter, setTopSearchFilter] = useState("");

  const theme = useTheme();


  const [addEmployeeOpen, setAddEmployeeOpen] = useState(false);

  /////////////// GRID /////////////
  // values to be sent to the backend

  const [data, setData] = useState({ data: [], total_count: 0 });
  const [gridLoading, setGridLoading] = useState(false);
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    // Hide columns status and traderName, the other columns will remain visible
    entityId: false,
  });

  const [search, setSearch] = useState("");

  useEffect(() => {
      setTriggerRefresh(!triggerRefresh)

    }, []);


    const handleExecute = async (values) => {


      setConfirmationOpen(false)
  
      dispatch(showLoading(""))
  
      if(values.action == "delete"){

        deleteHost(navigate)(values.entityId)
          .then((result) => {
          // Use the result.data as needed      
          dispatch(hideLoading());
              if(result.success){
                  
                enqueueSnackbar({
                  message: "Was deleted successfully",
                    variant: 'success',
                    anchorOrigin: {
                      vertical: 'top', // 'top', 'bottom'
                      horizontal: 'center', // 'left', 'center', 'right'
                    },
                    autoHideDuration: 3000, 
                    // persist: true
                  
                });
  
                setTriggerRefresh(!triggerRefresh);
  
              }else{



                enqueueSnackbar({
                  message: "Something went wrong during request",
                    variant: 'error',
                    anchorOrigin: {
                      vertical: 'top', // 'top', 'bottom'
                      horizontal: 'center', // 'left', 'center', 'right'
                    },
                    autoHideDuration: 3000, 
                    // persist: true
                  
                });
              }
  
  
        })
        .catch((error) => {
          dispatch(hideLoading());
  
          if (typeof error.action !== "undefined") {
            if (error.action == "relogin") {
              navigate("/");
            }else if(error.action == "force_delete"){
                console.log("force delete")
                setConfirmationOpen(true);
                setYesNoMessage("This entity has still referecnes to visits. Do you want to force the delete?")
                yesNoDialogEntity["action"] = "force_delete";
                setYesNoDialogEntity(yesNoDialogEntity)
            }
          }else if (error.hasOwnProperty('success')) {
            enqueueSnackbar({
              message: "Error "+error.message,
                variant: 'error',
                anchorOrigin: {
                  vertical: 'top', // 'top', 'bottom'
                  horizontal: 'center', // 'left', 'center', 'right'
                },
                autoHideDuration: 3000, 
                // persist: true
              
            });
          }
  
        });

      }else if(values.action == "force_delete"){

        deleteHostForce(navigate)(values.entityId)
          .then((result) => {
          // Use the result.data as needed      
          dispatch(hideLoading());
              if(result.success){
                  
                enqueueSnackbar({
                  message: "Was deleted successfully",
                    variant: 'success',
                    anchorOrigin: {
                      vertical: 'top', // 'top', 'bottom'
                      horizontal: 'center', // 'left', 'center', 'right'
                    },
                    autoHideDuration: 3000, 
                    // persist: true
                  
                });
  
                setTriggerRefresh(!triggerRefresh);
  
              }else{



                enqueueSnackbar({
                  message: "Something went wrong during request",
                    variant: 'error',
                    anchorOrigin: {
                      vertical: 'top', // 'top', 'bottom'
                      horizontal: 'center', // 'left', 'center', 'right'
                    },
                    autoHideDuration: 3000, 
                    // persist: true
                  
                });
              }
  
  
        })
        .catch((error) => {
          dispatch(hideLoading());
  
          if (typeof error.action !== "undefined") {
            if (error.action == "relogin") {
              navigate("/");
            }
          }else if (error.hasOwnProperty('success')) {
            enqueueSnackbar({
              message: "Error "+error.message,
                variant: 'error',
                anchorOrigin: {
                  vertical: 'top', // 'top', 'bottom'
                  horizontal: 'center', // 'left', 'center', 'right'
                },
                autoHideDuration: 3000, 
                // persist: true
              
            });
          }
  
        });

      }
  
        
  
    };

  const handleOptionCellActionClick = (id, action, row) => {
    if (action === 'edit') {
      // Handle edit action
      console.log('Edit record:', row);
      navigate(`/employees/${row.entityId}`)
    } else if (action === 'delete') {
      // Handle delete action
      console.log('Delete record:', row);
      setConfirmationOpen(true);
      setYesNoMessage("Do you really want to delete this entity?")
      row["action"] = "delete";
      setYesNoDialogEntity(row)
      
    }
  };


  

  const columns = [
    
    {
      field: "entityId",
      headerName: "Entity Id",
      flex: 1,
      type: 'number'
    },
    {
      field: 'picturePath',
      headerName: 'Picture',
      width: 100,
      renderCell: (params) => (
        params.row.picturePath != null ? (
          <Avatar {...stringAvatar(params.row.fullName)} alt={params.row.fullName} src={`${getUri(getSessionToken())}/host/${params.row.entityId}/picture/${params.row.picturePath}`}          />

        ) : (
          <Avatar {...stringAvatar(params.row.fullName)} />
        )
      ),
    },
    {
      field: "firstname",
      headerName: "First Name",
      flex: 1,
      renderCell: (params) => (
        <Link
          style={{ cursor: 'pointer', fontWeight: "bold", fontSize: '18px' }}
          onClick={() => {
            navigate(`/employees/${params.row.entityId}`)
          }}
        >
        {params.row.firstname}
      </Link>
      ),
    },
    {
      field: "lastname",
      headerName: "Last Name",
      flex: 1,
      renderCell: (params) => (
        <Link
          style={{ cursor: 'pointer', fontWeight: "bold", fontSize: '18px' }}
          onClick={() => {
            navigate(`/employees/${params.row.entityId}`)
          }}
        >
        {params.row.lastname}
      </Link>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      renderCell: (params) => (
        <Link
          style={{ cursor: 'pointer', fontWeight: "bold", fontSize: '18px' }}
          onClick={() => {
            navigate(`/employees/${params.row.entityId}`)
          }}
        >
        {params.row.email}
      </Link>
      ),
    },
    {
      field: "poNumber",
      headerName: tm.translate('clmPoNUmber', "Staff Id") ,
      flex: "auto",
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      filterable: false,
       // flex: 1,
      width: 80,
      sortable: false,
      renderCell: (params) => (
        <GridCellActions
          id={params.row.entityId}
          row={params.row}
          onOptionClick={handleOptionCellActionClick}
        />
      ),
    },
  ];

  const handleSearchInputChange = (e) => {

    // Update the state based on the previous state
    setTopSearchFilter(e.target.value);
    // // Perform other actions if needed
    setTriggerRefresh(!triggerRefresh);

  };

  const btnNewVisit = tm.translate('btnNewEmployee', 'New Employee');
  
  const buttons = [
    { type:'search',label: "Search employees..." , onChange: handleSearchInputChange, iconName: ''},
    { type:'button',label: btnNewVisit , onClick: () => { console.log("open"); 
              // openDrawer()
              navigate("/employees/new") 
              // setAddEmployeeOpen(true);
            }, iconName: '', btnId: 'btnNewEmployee'},

  ];

  const rowTemplateMobile = (row) => {

    
    return (
      <Box 
        display="flex" 
        alignItems="center" 
        padding="8px"
        borderRadius="8px"
      >
        {/* Avatar with fixed size */}
        {row.picturePath ? (
          <Avatar 
            {...stringAvatar(row.fullName)} 
            alt={row.fullName} 
            src={`${getUri(getSessionToken())}/host/${row.entityId}/picture/${row.picturePath}`} 
            sx={{ width: 40, height: 40, flexShrink: 0 }} // Ensures Avatar does not grow
          />
        ) : (
          <Avatar {...stringAvatar(row.fullName)} sx={{ width: 40, height: 40, flexShrink: 0 }} />
        )}
  
        {/* User Info - Takes full width */}
        <Box ml={2} flexGrow={1}> 
          <Typography variant="h6" sx={{ fontWeight: "bold", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
            {row.fullName}
          </Typography>  
  
          <Box display="flex" flexDirection="column">
            <Typography variant="caption" sx={{ cursor: 'pointer', fontWeight: "bold", fontSize: '14px', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
              {row.email}
            </Typography>
  
            <Typography variant="caption" sx={{ cursor: 'pointer', fontWeight: "bold", fontSize: '14px', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
              {row.poNumber}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };
  
  
    
    // Define row click event behavior
    const handleRowClick = (row) => {
  
      navigate(`/employees/${row.entityId}`)
  
    };


  return (
    <Box m="1rem 2rem">
      <Header title="Employee Directory" subtitle="List of employees" elements={buttons} />
      <DialogYesNo
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={handleExecute}
        theme={theme}
        message={yesNoMessage}
        entity={yesNoDialogEntity}
      />
      <DialogAddEmployee
        open={addEmployeeOpen}
        onClose={() => setAddEmployeeOpen(false)}
        onSave={(entity) => {
          // loadedFormData.picturePath = entity.picturePath;
          // setLoadedFormData({ ...loadedFormData });
          // setAddEmployeeOpen(false);
        }}
        currentEntity={null}
        entityType="host"
      />
      {/* </Box> */}
      <SadevioDataGridPro columns ={columns} url={`host`} 
      
        triggerRefresh= {triggerRefresh} 
        mobileSupport={true} 
        rowTemplateMobile={rowTemplateMobile} 
        rowClickEvent={handleRowClick} 
        extraFilter={topSearchFilter}
        sortDataDESC={false}/>
      
    </Box>
  );
};

export default EmployeeDirectory;