import React, { useEffect, useState } from "react";
import { Box, Button, FormControlLabel, ListItemIcon, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Apps, Close, Save } from "@mui/icons-material";
import IOSSwitch from "components/IOSSwitch";
import {  getExternalDevice,  putExternalDevice } from "hooks/HttpRequests";
import * as yup from "yup";
import { Formik } from "formik";
import { Oval } from "react-loader-spinner";
import { useFrontDeskManager } from "components/FrontDeskManager";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "state/loadingSlicer";
import { useIntegrationConfig } from "components/IntegrationConfigManager";
import tm from 'components/TranslationManager';
import { enqueueSnackbar } from "notistack";


const ApplicationModeConfig = ({id}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  const { palette } = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { selectedFrontDesk } = useFrontDeskManager();
  const { setShowSilentAlarm } = useIntegrationConfig();

  const initialValuesRegister = {
    modeCheckIn: true,
    modeForceQrCheckIn: false,
    modeTemporaryCardReplacement: true,
    modeCardReplacement: false,
    modePhotoStation: false,
    modeOnboarding: false,
    modeRetrievePackage: false,
    modePickUpPackage: false
  };

  const [loadedFormData, setLoadedFormData] = useState({ initialValuesRegister });
  const [entireConfigData, setEntireConfigData] = useState({ });
  const [entityData, setEntityData] = useState({ });

  const [loading, setLoading] = useState(false);
  const [showConf, setShowConf] = useState(false);


  useEffect(() => {
    if (showConf) {
      loadData();
    }
  }, [showConf]);

  const registerSchema = yup.object().shape({
    // usersToNotify: yup.string().required("required"),
  });

  

  const toggleShowConf = () => {
    setShowConf(!showConf);
  };

  const commonStyles = {
    bgcolor: theme.palette.primary[600],
    m: "1rem 0rem 1rem 0rem",
    borderRadius: 1,
    borderColor: theme.palette.secondary[100],
  };

  const loadData = async () => {
    setLoading(true);
    dispatch(showLoading(""));

    getExternalDevice(navigate)(id)
      .then((result) => {
        if (result.success) {
          if(result.data.deviceLocalConfig != ""){

            // Parse the string into an object
            let deviceConfig = {};
            try {
              deviceConfig = JSON.parse(result.data.deviceLocalConfig);
            } catch (error) {
              console.error("Error parsing JSON:", error);
            }
            if(typeof deviceConfig.applicationModes != 'undefined'){
              setLoadedFormData(deviceConfig.applicationModes)
            }
            setEntityData(result.data)
            
            setEntireConfigData(deviceConfig)
          }
        }
        setLoading(false);
        dispatch(hideLoading());

      })
      .catch((error) => {
        dispatch(hideLoading());
        setLoading(false);
      });
  };

  const handleFormSubmit = (values) => {

    var submitData = {};
    entireConfigData.applicationModes = values;
    const deviceLocalConfigString = JSON.stringify(entireConfigData);
    entityData.deviceLocalConfig = deviceLocalConfigString;

    entityData["frontDeskEntityId"] = entityData.frontDesk.entityId
    delete entityData.frontDesk

    submitData["data"] = entityData;

    dispatch(showLoading(""))

    putExternalDevice(navigate)(submitData)
      .then((result) => {
      // Use the result.data as needed
      if (result.success) {
      
          if(result.success){
              //   setCopmanyName("- "+result.data.name);
              // setLoadedFormData(result.data)

              enqueueSnackbar({
                  message: "Entity was saved",
                    variant: 'success',
                    anchorOrigin: {
                      vertical: 'top', // 'top', 'bottom'
                      horizontal: 'center', // 'left', 'center', 'right'
                    },
                    autoHideDuration: 3000, 
                    // persist: true
                  
                });
                loadData();
          }else{
              enqueueSnackbar({
                  message: "Error "+result.message,
                      variant: 'error',
                      anchorOrigin: {
                      vertical: 'top', // 'top', 'bottom'
                      horizontal: 'center', // 'left', 'center', 'right'
                      },
                      autoHideDuration: 3000, 
                      // persist: true
                  
                  });
          }
          dispatch(hideLoading());

      }
      })
      .catch((error) => {
      dispatch(hideLoading());

      if (typeof error.action !== "undefined") {
          if (error.action === "relogin") {
          navigate("/");
          }
      }else if (error.hasOwnProperty('success')) {
          enqueueSnackbar({
          message: "Error "+error.message,
              variant: 'error',
              anchorOrigin: {
              vertical: 'top', // 'top', 'bottom'
              horizontal: 'center', // 'left', 'center', 'right'
              },
              autoHideDuration: 3000, 
              // persist: true
          
          });
      }

      });
  };

  return (
    <Box sx={{ ...commonStyles, border: 1 }}>
      <Box display="flex" alignItems="center">
        <ListItemIcon>
          <Apps fontSize="large" sx={{ m: "1.5rem 0rem 1.5rem 1.5rem" }} />
        </ListItemIcon>
        <Box sx={{ m: "1.5rem 1.5rem 1.5rem 1.5rem" }}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="h3" color={theme.palette.secondary[100]} fontWeight="bold">
                Application Mode
            </Typography>
          </Box>
          <Typography variant="h5" color={theme.palette.secondary[300]}>
            Configure all needed application modes
          </Typography>
        </Box>
        <Box ml="auto">
          {!showConf && (
            <Button
              variant="contained"
              onClick={toggleShowConf}
              sx={{
                backgroundColor: theme.palette.secondary.light,
                color: theme.palette.background.alt,
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                m: "0rem 1rem 0rem 0rem",
                "&:hover": { color: theme.palette.secondary.dark },
              }}
            >
              EDIT
            </Button>
          )}
          {showConf && (
            <Button
              variant="contained"
              onClick={toggleShowConf}
              sx={{
                backgroundColor: theme.palette.secondary.light,
                color: theme.palette.background.alt,
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                m: "0rem 1rem 0rem 0rem",
                "&:hover": { color: theme.palette.secondary.dark },
              }}
            >
              <Close />
            </Button>
          )}
        </Box>
      </Box>

      {showConf && (
        <Box>
          {loading ? (
            <Box sx={{ width: "100%", height: "100%", padding: "20px", display: "flex", justifyContent: "center" }}>
            <Oval
                visible={true}
                height="80"
                width="80"
                color="#ffedc2"
                secondaryColor="#ffedc2"
                ariaLabel="oval-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </Box>
          ) : (
            <Box sx={{ padding: "20px"}}>
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={loadedFormData || initialValuesRegister}
                validationSchema={registerSchema}
                enableReinitialize={true}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  resetForm,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Paper
                      elevation={3}
                      style={{
                        padding: 20,
                        backgroundColor: theme.palette.background.alt,
                        backgroundImage: "none",
                        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        gap={2} // Ensures space between switches
                        sx={{ width: "100%" }} // Full width for mobile
                      >
                        <FormControlLabel
                          control={
                            <IOSSwitch
                              sx={{ m: 1 }}
                              checked={values.modeCheckIn}
                              name="modeCheckIn"
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          }
                          label={<span style={{ color: theme.palette.secondary.light }}>Check In Mode</span>}
                        />

                        <FormControlLabel
                          control={
                            <IOSSwitch
                              sx={{ m: 1 }}
                              checked={values.modeForceQrCheckIn}
                              name="modeForceQrCheckIn"
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          }
                          label={<span style={{ color: theme.palette.secondary.light }}>Force QR Code Check-In</span>}
                        />

                        <FormControlLabel
                          control={
                            <IOSSwitch
                              sx={{ m: 1 }}
                              checked={values.modeTemporaryCardReplacement}
                              name="modeTemporaryCardReplacement"
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          }
                          label={<span style={{ color: theme.palette.secondary.light }}>Temporary Card Replacement</span>}
                        />

                        <FormControlLabel
                          control={
                            <IOSSwitch
                              sx={{ m: 1 }}
                              checked={values.modeCardReplacement}
                              name="modeCardReplacement"
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          }
                          label={<span style={{ color: theme.palette.secondary.light }}>Card Replacement</span>}
                        />

                        <FormControlLabel
                          control={
                            <IOSSwitch
                              sx={{ m: 1 }}
                              checked={values.modePhotoStation}
                              name="modePhotoStation"
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          }
                          label={<span style={{ color: theme.palette.secondary.light }}>Photo Station</span>}
                        />

                        <FormControlLabel
                          control={
                            <IOSSwitch
                              sx={{ m: 1 }}
                              checked={values.modeOnboarding}
                              name="modeOnboarding"
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          }
                          label={<span style={{ color: theme.palette.secondary.light }}>Onboarding</span>}
                        />

                        <FormControlLabel
                          control={
                            <IOSSwitch
                              sx={{ m: 1 }}
                              checked={values.modeRetrievePackage}
                              name="modeRetrievePackage"
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          }
                          label={<span style={{ color: theme.palette.secondary.light }}>Retrieve Packages</span>}
                        />

                        <FormControlLabel
                          control={
                            <IOSSwitch
                              sx={{ m: 1 }}
                              checked={values.modePickUpPackage}
                              name="modePickUpPackage"
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          }
                          label={<span style={{ color: theme.palette.secondary.light }}>Pick Up Packages</span>}
                        />
                      </Box>
                    </Paper>
                    
                        <Button
                          startIcon={<Save />}
                          type="submit"
                          sx={{
                            p: "1rem",
                            m: "1rem 0rem 0rem 0rem",
                            backgroundColor: palette.secondary.light,
                            color: palette.background.alt,
                            fontWeight: "bold",
                            "&:hover": {
                              color: palette.primary.main,
                              "&:hover": { color: theme.palette.secondary.dark },
                              fontWeight: "bold",
                            },
                          }}
                        >
                          Save
                        </Button>
                  </form>
                )}
              </Formik>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ApplicationModeConfig;
