import React, { useEffect, useState } from "react";
import { Box, Button, ListItemIcon, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Close, Password, Save } from "@mui/icons-material";
import { CssTextField } from "components/StyledComponents";
import { getHost, putUserProfileHostPassword } from "hooks/HttpRequests";
import * as yup from "yup";
import { Formik } from "formik";
import { Oval } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "state/loadingSlicer";
import { useUserProfileManager } from "components/UserProfileProvider";
import { enqueueSnackbar } from "notistack";

const ProfileChangeUserPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  const { palette } = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { userProfile, userProfleLoading } = useUserProfileManager();

  const [loadedFormData, setLoadedFormData] = useState({ active: false });
  const [loading, setLoading] = useState(false);
  const [showConf, setShowConf] = useState(false);

  const [authType, setAuthType] = useState("app");


  useEffect(() => {
    if (showConf) {
      loadData();
    }
  }, [showConf]);

  const registerSchema = yup.object().shape({
      currentPassword: yup.string().required("required"),
      newPassword: yup.string().required("required"),
      newPasswordConfirm: yup.string().required("required"),
  });

  const initialValuesRegister = {
    currentPassword: "",
    newPassword: "",
    newPasswordConfirm: "false",
  };

  const toggleShowConf = () => {
    setShowConf(!showConf);
  };

  const commonStyles = {
    bgcolor: theme.palette.primary[600],
    m: "1rem 0rem 1rem 0rem",
    borderRadius: 1,
    borderColor: theme.palette.secondary[100],
  };

  const loadData = async () => {
    setLoading(true);

    getHost(navigate)(userProfile.session.user.userId)
      .then((result) => {
        if (result.success) {
          setLoadedFormData(result.data)
          setAuthType(result.data.authType);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleFormSubmit = (values) => {

    var submitData = {};
    var data = {};
    data["currentPassword"] = values.currentPassword;
    data["newPassword"] = values.newPassword;
    data["newPasswordConfirm"] = values.newPasswordConfirm;

    submitData["data"]= data;

    if(values.newPassword == values.newPasswordConfirm){
      dispatch(showLoading(""))

      putUserProfileHostPassword(navigate)(submitData)
        .then((result) => {

          if (result.success) {
            setLoadedFormData(result.data);
            setShowConf(false);
            
            enqueueSnackbar({
              message: "Entity password changed",
                variant: 'success',
                anchorOrigin: {
                  vertical: 'top', // 'top', 'bottom'
                  horizontal: 'center', // 'left', 'center', 'right'
                },
                autoHideDuration: 3000, // 5000 milliseconds = 5 seconds
                // persist: true
              
            });
          }
          dispatch(hideLoading());
        })
        .catch((error) => {        
          dispatch(hideLoading());
          enqueueSnackbar({
            message: "Error - "+error.message,
              variant: 'error',
              anchorOrigin: {
                vertical: 'top', // 'top', 'bottom'
                horizontal: 'center', // 'left', 'center', 'right'
              },
              autoHideDuration: 3000, 
              // persist: true
            
          });
        });

    }else{
      enqueueSnackbar({
        message: "Error - New password does not match",
          variant: 'error',
          anchorOrigin: {
            vertical: 'top', // 'top', 'bottom'
            horizontal: 'center', // 'left', 'center', 'right'
          },
          autoHideDuration: 3000, 
          // persist: true
        
      });
    }

    
  };

  return (
    <Box sx={{ ...commonStyles, border: 1 }}>
      <Box display="flex" alignItems="center">
        <ListItemIcon>
          <Password fontSize="large" sx={{ m: "1.5rem 0rem 1.5rem 1.5rem" }} />
        </ListItemIcon>
        <Box sx={{ m: "1.5rem 1.5rem 1.5rem 1.5rem" }}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="h3" color={theme.palette.secondary[100]} fontWeight="bold">
              Authentication
            </Typography>
          </Box>
          <Typography variant="h5" color={theme.palette.secondary[300]}>
              Set the authetnication type
          </Typography>
        </Box>
        <Box ml="auto">
          {!showConf && (
            <Button
              variant="contained"
              onClick={toggleShowConf}
              sx={{
                backgroundColor: theme.palette.secondary.light,
                color: theme.palette.background.alt,
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                m: "0rem 1rem 0rem 0rem",
                "&:hover": { color: theme.palette.secondary.light },
              }}
            >
              EDIT
            </Button>
          )}
          {showConf && (
            <Button
              variant="contained"
              onClick={toggleShowConf}
              sx={{
                backgroundColor: theme.palette.secondary.light,
                color: theme.palette.background.alt,
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                m: "0rem 1rem 0rem 0rem",
                "&:hover": { color: theme.palette.secondary.light },
              }}
            >
              <Close />
            </Button>
          )}
        </Box>
      </Box>

      {showConf && (
        <Box>
          {loading ? (
            <Box sx={{ width: "100%", height: "100%", padding: "20px", display: "flex", justifyContent: "center" }}>
            <Oval
                visible={true}
                height="80"
                width="80"
                color="#ffedc2"
                secondaryColor="#ffedc2"
                ariaLabel="oval-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </Box>
          ) : (
            <Box sx={{ padding: "20px"}}>

              { 
                  authType == "app" && (
                    <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={loadedFormData || initialValuesRegister}
                    validationSchema={registerSchema}
                    enableReinitialize={true}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      setFieldValue,
                      resetForm,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <Paper
                          elevation={3}
                          style={{
                            padding: 20,
                            backgroundColor: theme.palette.background.alt,
                            backgroundImage: "none",
                            boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
                          }}
                          sx={{ "& > div": { gridColumn: isNonMobile ? undefined : "span 4" } }}
                        >
    
                        <CssTextField
                            label="Current password"
                            type="password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            required
                            value={values.currentPassword}
                            name="currentPassword"
                            error={Boolean(touched.currentPassword) && Boolean(errors.currentPassword)}
                            helperText={touched.currentPassword && errors.currentPassword}
                            sx={{ mb: 2, gridColumn: "span 4" }}
                            theme={theme}
                            // InputProps={{
                            //   endAdornment: (
                            //     <InputAdornment position="end">
                            //       <Tooltip  title={
                            //           <Typography variant="body1" style={{ fontSize: '16px' }}>
                            //           Add multiple email addresses separated by ; who should be notified.
                            //           </Typography>
                            //       }>
                            //         <IconButton>
                            //           <HelpOutline />
                            //         </IconButton>
                            //       </Tooltip>
                            //     </InputAdornment>
                            //   ),
                            // }}
                            fullWidth
                          />
    
                          <CssTextField
                            label="New Password"
                            type="password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            required
                            value={values.newPassword}
                            name="newPassword"
                            error={Boolean(touched.newPassword) && Boolean(errors.newPassword)}
                            helperText={touched.newPassword && errors.newPassword}
                            sx={{ mb: 2, gridColumn: "span 4" }}
                            theme={theme}
                            // InputProps={{
                            //   endAdornment: (
                            //     <InputAdornment position="end">
                            //       <Tooltip  title={
                            //           <Typography variant="body1" style={{ fontSize: '16px' }}>
                            //           Add multiple email addresses separated by ; who should be notified.
                            //           </Typography>
                            //       }>
                            //         <IconButton>
                            //           <HelpOutline />
                            //         </IconButton>
                            //       </Tooltip>
                            //     </InputAdornment>
                            //   ),
                            // }}
                            fullWidth
                          />
    
                        <CssTextField
                            label="New Password Confirmation"
                            type="password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            required
                            value={values.newPasswordConfirm}
                            name="newPasswordConfirm"
                            error={Boolean(touched.newPasswordConfirm) && Boolean(errors.newPasswordConfirm)}
                            helperText={touched.newPasswordConfirm && errors.newPasswordConfirm}
                            sx={{ mb: 2, gridColumn: "span 4" }}
                            theme={theme}
                            // InputProps={{
                            //   endAdornment: (
                            //     <InputAdornment position="end">
                            //       <Tooltip  title={
                            //           <Typography variant="body1" style={{ fontSize: '16px' }}>
                            //           Add multiple phone numbers separated by ; who should be notified.
                            //           </Typography>
                            //       }>
                            //         <IconButton>
                            //           <HelpOutline />
                            //         </IconButton>
                            //       </Tooltip>
                            //     </InputAdornment>
                            //   ),
                            // }}
                            fullWidth
                          />
    
                              
                        </Paper>
                        
                            <Button
                              startIcon={<Save />}
                              type="submit"
                              sx={{
                                p: "1rem",
                                m: "1rem 0rem 0rem 0rem",
                                backgroundColor: palette.secondary.light,
                                color: palette.background.alt,
                                fontWeight: "bold",
                                "&:hover": {
                                  color: palette.primary.main,
                                  backgroundColor: palette.secondary.light,
                                  fontWeight: "bold",
                                },
                              }}
                            >
                              Save
                            </Button>
                      </form>
                    )}
                  </Formik>
                  
                  ) }

                { 
                  authType == "staff_id" && (
                    <Typography variant="h4" color={theme.palette.secondary[300]}>
                      Please use your Staff ID to authenticate
                  </Typography>
                  )
                }

                { 
                  authType == "ldap" && (
                    <Typography variant="h4" color={theme.palette.secondary[300]}>
                      Use your domain password
                  </Typography>
                  )
                }

                { 
                  authType == "azure_ad" && (
                    <Typography variant="h4" color={theme.palette.secondary[300]}>
                      Use your domain password
                  </Typography>
                  )
                }

                  

              
             
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ProfileChangeUserPassword;
