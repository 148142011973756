import React, { useEffect, useState } from 'react';
import { Box, Typography,  Button, useTheme, Grid, useMediaQuery } from '@mui/material';
import { Android, Apple} from '@mui/icons-material';
import {  deleteExternalDevice, getDevice, postExternalDevice, putExternalDevice } from 'hooks/HttpRequests';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import { hideLoading, showLoading } from 'state/loadingSlicer';
import { useFrontDeskManager } from 'components/FrontDeskManager';
import DialogYesNo from 'components/dialog/DialogYesNo';
import DeviceForm from './deviceForm';


const DeviceDetailGeneral = ({globalType, navigate, id, route }) => {

    // var isAuth = useSelector((state) => state.auth.token);
    const dispatch = useDispatch();

    const theme = useTheme();
    const {palette} = useTheme();
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const isNonMediumScreens = useMediaQuery("(min-width: 900px)");

    const {  frontDeskLoading, allAvailableFrontDesks } = useFrontDeskManager();

    const [takePictureOpen, setTakePictureOpen] = useState(false);

    var imagePath = `${process.env.PUBLIC_URL}/kiosks/kiosk_v_2_2.svg`;
    const [typeImage, setTypeImage] = useState(imagePath);


    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [yesNoMessage, setYesNoMessage] = useState("");
    const [yesNoDialogEntity, setYesNoDialogEntity] = useState({})

    const [gridLoading, setGridLoading] = useState(false);



const [isAddMode, setIsAddMode] = useState(false);

const [loadedFormData, setLoadedFormData] = useState({ entityId: null, 
            name: "", 
            address: "", 
            city: "",
            countryCode: "", 
            gender: "", 
            company: "", 
            notes: "", 
            reason: "",
            source: "",
            stateCode: "",
            zip: "",
            });



    useEffect(() => {

        setIsAddMode(route === "new");
        if (route !== "new") {
            loadData(id);
        } else {
            //do nothing
        }

        if(route === "new"){

        }

    }, [id, route]);

  const loadData = async (id) => {

        getDevice(navigate)(id)
        .then((result) => {

        if(result.success){

            setLoadedFormData(result.data)

            var imagePath = `${process.env.PUBLIC_URL}/kiosks/kiosk_v_2_2.svg`;
    
            if(result.data.type == "kiosk 1_1"){
              imagePath = `${process.env.PUBLIC_URL}/kiosks/kiosk_v_1_1.svg`;
            }else if(result.data.type == "kiosk 2_1"){
              imagePath = `${process.env.PUBLIC_URL}/kiosks/kiosk_v_2_1.svg`;
            }else if(result.data.type == "kiosk 2_2"){
              imagePath = `${process.env.PUBLIC_URL}/kiosks/kiosk_v_2_2.svg`;
            }else if(result.data.type == "kiosk tablet"){
              return <Android style={{ fontSize: 40, color: theme.palette.secondary[100] }} />;
            }else if(result.data.type == "iOs Kiosk"){
              return <Apple style={{ fontSize: 40, color: theme.palette.secondary[100] }} />;
            }

            setTypeImage(imagePath)

        }

        })
        .catch((error) => {
            setGridLoading(false)
        });
  };

  const handleSubmit = (values) => {


    loadedFormData["active"] = values.active

    loadedFormData["deviceName"] = values.deviceName
    loadedFormData["cardIdProcessor"] = values.cardIdProcessor
    loadedFormData["frontDeskEntityId"] = values.frontDesk.entityId
    delete loadedFormData.frontDesk

    loadedFormData["ipAddress"] = values.ipAddress
    loadedFormData["lockMessage"] = values.lockMessage
    loadedFormData["lockScreen"] = values.lockScreen

    loadedFormData["macAddress"] = values.macAddress
    loadedFormData["localPin"] = values.localPin
    loadedFormData["reason"] = values.reason
    loadedFormData["type"] = values.type

    var submitData= {}
    submitData["data"]= loadedFormData;

    
    if(isAddMode){

        dispatch(showLoading(""));

        postExternalDevice(navigate)(submitData)
        .then((result) => {
            if (result.success) {
        
                setIsAddMode(false);
                navigate(`/global/devices/${result.data.entityId}`)
            
            }
        dispatch(hideLoading()); 
        })
        .catch((error) => {
        dispatch(hideLoading());
    
        if (typeof error.action !== "undefined") {
            if (error.action === "relogin") {
            navigate("/");
            }
        }else if (error.hasOwnProperty('success')) {
            enqueueSnackbar({
            message: "Error "+error.message,
                variant: 'error',
                anchorOrigin: {
                vertical: 'top', // 'top', 'bottom'
                horizontal: 'center', // 'left', 'center', 'right'
                },
                autoHideDuration: 3000, 
                // persist: true
            
            });
        }
    
        });

    }else{
        dispatch(showLoading(""));

        putExternalDevice(navigate)(submitData)
        .then((result) => {
        // Use the result.data as needed
        if (result.success) {
        
            if(result.success){
                //   setCopmanyName("- "+result.data.name);
                // setLoadedFormData(result.data)

                enqueueSnackbar({
                    message: "Entity was saved",
                      variant: 'success',
                      anchorOrigin: {
                        vertical: 'top', // 'top', 'bottom'
                        horizontal: 'center', // 'left', 'center', 'right'
                      },
                      autoHideDuration: 3000, 
                      // persist: true
                    
                  });
                  setLoadedFormData(result.data)

            }else{
                enqueueSnackbar({
                    message: "Error "+result.message,
                        variant: 'error',
                        anchorOrigin: {
                        vertical: 'top', // 'top', 'bottom'
                        horizontal: 'center', // 'left', 'center', 'right'
                        },
                        autoHideDuration: 3000, 
                        // persist: true
                    
                    });
            }
            dispatch(hideLoading());

        }
        })
        .catch((error) => {
        dispatch(hideLoading());

        if (typeof error.action !== "undefined") {
            if (error.action === "relogin") {
            navigate("/");
            }
        }else if (error.hasOwnProperty('success')) {
            enqueueSnackbar({
            message: "Error "+error.message,
                variant: 'error',
                anchorOrigin: {
                vertical: 'top', // 'top', 'bottom'
                horizontal: 'center', // 'left', 'center', 'right'
                },
                autoHideDuration: 3000, 
                // persist: true
            
            });
        }

        });
        }
    
  };

  const onDeleteClicked = () => {

    // Handle delete action
    setConfirmationOpen(true);
    setYesNoMessage("Do you really want to delete this entity?")
    loadedFormData["action"] = "delete";
    setYesNoDialogEntity(loadedFormData)
          
        
  }

  const handleExecute = async (values) => {


    setConfirmationOpen(false)

    dispatch(showLoading(""))

    if(values.action == "delete"){

      deleteExternalDevice(navigate)(values.entityId)
        .then((result) => {
        // Use the result.data as needed      
        dispatch(hideLoading());
            if(result.success){
                
              enqueueSnackbar({
                message: "Was deleted successfully",
                  variant: 'success',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
                
              });

            navigate("/global/devices");

            }else{

              enqueueSnackbar({
                message: "Something went wrong during request",
                  variant: 'error',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
                
              });
            }


      })
      .catch((error) => {
        dispatch(hideLoading());

        if (typeof error.action !== "undefined") {
          if (error.action === "relogin") {
            navigate("/");
          }else if(error.action == "force_delete"){
              console.log("force delete")
              setConfirmationOpen(true);
              setYesNoMessage("This entity has still referecnes to visits. Do you want to force the delete?")
              yesNoDialogEntity["action"] = "force_delete";
              setYesNoDialogEntity(yesNoDialogEntity)
          }
        }else if (error.hasOwnProperty('success')) {
          enqueueSnackbar({
            message: "Error "+error.message,
              variant: 'error',
              anchorOrigin: {
                vertical: 'top', // 'top', 'bottom'
                horizontal: 'center', // 'left', 'center', 'right'
              },
              autoHideDuration: 3000, 
              // persist: true
            
          });
        }

      });

    }
  };


  // Render loading state if loadedFormData is null
  if (!loadedFormData) {
    return <div>Loading...</div>;
  }

  return (
    <Box sx={{ p: isNonMobile ? 2 : 0 }}>
      <DialogYesNo open={confirmationOpen} onClose={() => setConfirmationOpen(false)} theme={theme} onConfirm={handleExecute} message={yesNoMessage} entity={yesNoDialogEntity} />

      <Typography variant={isNonMobile ? "h4" : "h5"} fontWeight="bold" color={theme.palette.secondary[100]} mb={2}>
        General Information
      </Typography>

      <Grid container spacing={isNonMobile ? 3 : 2}>
        {/* Left Section (Form) */}
        <Grid item xs={12} md={8}>
          <DeviceForm loadedFormData={loadedFormData} onSubmit={handleSubmit} onDeleteClicked={onDeleteClicked} isAddMode={isAddMode} />
        </Grid>

        {/* Right Section (Image/Icon) */}
        <Grid item xs={12} md={4} textAlign="center">
          {loadedFormData.type === "kiosk tablet" ? (
            <Android sx={{ fontSize: isNonMobile ? 60 : 40, color: theme.palette.secondary[100] }} />
          ) : loadedFormData.type === "iOs Kiosk" ? (
            <Apple sx={{ fontSize: isNonMobile ? 60 : 40, color: theme.palette.secondary[100] }} />
          ) : (
            <img src={`${process.env.PUBLIC_URL}/kiosks/kiosk_v_2_2.svg`} alt={loadedFormData.type} style={{ width: "100%", maxWidth: 220, height: "auto", borderRadius: 5 }} />
          )}

          <Typography variant="h6" fontWeight="bold" mt={1}>
            {loadedFormData.label}
          </Typography>
        </Grid>
      </Grid>

      {/* Action Buttons */}
      <Box sx={{ display: "flex", flexDirection: isNonMobile ? "row" : "column", justifyContent: "space-between", mt: 2 }}>
        <Button variant="outlined" onClick={() => navigate(-1)} sx={{ display: isNonMobile ? "block" : "none" }}>
          Back
        </Button>
        
      </Box>
    </Box>
  );
};

export default DeviceDetailGeneral;
