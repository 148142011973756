import React, { useEffect, useState } from "react";
import {  Box, useMediaQuery, useTheme } from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Header from "components/Header";
import {  useLocation, useNavigate, useParams } from "react-router-dom";
import DeviceDetailGeneral from "./details/deviceDetailGeneral";
import DeviceConfiguration from "./details/deviceConfiguration";
import ConfigData from "components/ConfigData";
import VisitorDocuments from "scenes/visitors/visitors/details/visitorDocuments";
import ApplicationMessages from "components/ApplicationMessages";


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const DeviceDetail = ({ route }) => {
  const navigate = useNavigate();
  const location = useLocation();
  
  const theme = useTheme();
  const {palette} = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const isNonMediumScreens = useMediaQuery("(min-width: 900px)");


  const [gridLoading, setGridLoading] = useState(false);
  const { id } = useParams(); // Accessing the id parameter from the URL

  useEffect(() => {
    
    if (route !== "new") {

      // setGridLoading(true)

      // getHost(navigate)(id)
      // .then((result) => {

      //   console.log(result)
      //   if(result.success){
      //       setCopmanyName("- "+result.data.fullName);
      //   }
      //   // setData(result)
      //   setGridLoading(false)

      // })
      // .catch((error) => {

      //   setGridLoading(false)
      // });
    } else {
        //do nothing
    }

    

  }, [id]);


  const tabs = [
    { label: 'General', path: '/general', id: "tabGeneral" },
    ...(route === "update" ? [
      { label: 'Device Configuration', path: '/configuration' , id: "tabConfiguration"},
      // { label: 'Timeline', path: '/timeline', id: "tabTimeline" },
      // { label: 'Visit History', path: '/history', id: "tabHistory" },
      { label: 'Documents', path: '/documents', id: "tabDocuments" },
      { label: 'Notes', path: '/messages', id: "tabMessages" },
    ] : []),
  ];
  

  const currentPath = location.pathname;
  const selectedTab = tabs.find(tab => currentPath.includes(tab.path));
  const selectedTabIndex = selectedTab ? tabs.indexOf(selectedTab) : 0;

  const [value, setValue] = useState(selectedTabIndex);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const selectedTab = tabs[newValue];
    navigate(`/global/devices/${id}${selectedTab.path}`);
  };

  useEffect(() => {
    const selectedTab = tabs.find(tab => currentPath.includes(tab.path));
    const selectedTabIndex = selectedTab ? tabs.indexOf(selectedTab) : 0;
    setValue(selectedTabIndex);
  }, [currentPath, tabs]);

  return (
    <Box sx={{ m: isNonMobile ? "0rem 2rem" : "0rem 0rem" }} >
      <Header title={`Device Details`} subtitle="" elements={null} backbutton/>

      <Box height="90vh" sx={{
          "& .MuiTab-root.MuiTab-textColorPrimary.Mui-selected": {
            color: `${theme.palette.secondary[200]} !important`,
            fontWeight: 'bold'
          },
          
        }}>

        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab.label} {...a11yProps(index)} />
          ))}
        </Tabs>
        {tabs.map((tab, index) => (
          <CustomTabPanel key={index} value={value} index={index}>
            {tab.id === 'tabGeneral' && (
              <DeviceDetailGeneral
                visitorType="Visitor Type"
                description="Description"
                globalType={true}
                navigate={navigate}
                id={id}
                route={route}
                // theme={theme}
              />
            )}
          {tab.id === 'tabConfiguration' && (
              <DeviceConfiguration
                visitorType="Visitor Type"
                description="Description"
                globalType={true}
                navigate={navigate}
                id={id}
                route={route}
                // theme={theme}
              />
            )}
            {tab.id === 'tabDocuments' && (
              // <Typography>This is the Security tab content</Typography>
              <VisitorDocuments
                visitorType="Visitor Type"
                description="Description"
                globalType={true}
                navigate={navigate}
                id={id}
                route={route}
                keyname = {ConfigData.attachmentKeynames.ATTACHMENT_KEYNAME_EXTERNAL_DEVICE_ATTACHMENT}
                table = {ConfigData.attachmentTables.ATTACHMENT_TABLE_EXTERNAL_DEVICES}
                // theme={theme}
              />
            )}
            {tab.id === 'tabMessages' && (
              <ApplicationMessages
                visitorType="Visitor Type"
                description="Description"
                globalType={true}
                navigate={navigate}
                id={id}
                entityKeyname = {ConfigData.attachmentKeynames.ATTACHMENT_KEYNAME_EXTERNAL_DEVICE_NOTE}
                entityTable = {ConfigData.attachmentTables.ATTACHMENT_TABLE_EXTERNAL_DEVICES}
                // theme={theme}
              />
            )}

           

          </CustomTabPanel>
        ))}
      </Box>
    </Box>
  );
};

export default DeviceDetail;

