import React, { useEffect, useState } from "react";
import {  Box, Button, FormControlLabel, Grid, ListItemIcon, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Close, HelpOutline, LockClock, Save } from "@mui/icons-material";
import * as yup from "yup";
import { Formik } from "formik";
import { Oval } from "react-loader-spinner";
import { useFrontDeskManager } from "components/FrontDeskManager";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "state/loadingSlicer";
import { useIntegrationConfig } from "components/IntegrationConfigManager";
import tm from 'components/TranslationManager';
import { TimePicker } from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";


const LocationBusinessHoursConfig = ({ businessHours, setBusinessHours, onSave }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  const { palette } = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const days = [
    { key: "mo", label: "Monday" },
    { key: "tu", label: "Tuesday" },
    { key: "we", label: "Wednesday" },
    { key: "th", label: "Thursday" },
    { key: "fr", label: "Friday" },
    { key: "sa", label: "Saturday" },
    { key: "su", label: "Sunday" },
  ];
  
  const parseBusinessHours = (str) => {
    try {
      return JSON.parse(str);
    } catch {
      return {};
    }
  };

  const parsedHours = parseBusinessHours(businessHours);

  const handleChange = (dayKey, timeType, value) => {
    const updated = {
      ...parsedHours,
      [`${dayKey}${timeType}`]: value,
    };
    setBusinessHours(JSON.stringify(updated));
  };

  const toDayjs = (timeStr) => {
    if (!timeStr) return null;
    return dayjs(timeStr, ["h:mm A", "H:mm"]);
  };
  
  const to12hString = (dayjsObj) => {
    return dayjsObj?.format("h:mm A") || "";
  };
  
  const initialValuesRegister = {
    phoneNumbers: "",
    emailAddresses: "",
    active: false,
  };

  const [loadedFormData, setLoadedFormData] = useState({ initialValuesRegister });
  const [loading, setLoading] = useState(false);
  const [showConf, setShowConf] = useState(false);

  const defaultCleanUpType= { label: "Full Cleanup", value: "all" };
    const [selectedClenupType, setSelectedClenupType] = useState("all"); // Default value

    const cleanUpTypes = [
      defaultCleanUpType,
        { label: 'Selective Retention', value: "selective" },
    ]

  useEffect(() => {
    if (showConf) {
      // loadData();
    }
  }, [showConf]);

  const registerSchema = yup.object().shape({
    // usersToNotify: yup.string().required("required"),
  });

  

  const toggleShowConf = () => {
    setShowConf(!showConf);
  };

  const commonStyles = {
    bgcolor: theme.palette.primary[600],
    m: "1rem 0rem 1rem 0rem",
    borderRadius: 1,
    borderColor: theme.palette.secondary[100],
  };

  const handleFormSubmit = () => {
    // You already updated the business hours via handleChange
    if (typeof onSave === "function") {
      onSave(); // 🔥 Trigger parent form save
    }
    toggleShowConf(); // Optional: hide config after saving
  };


  return (
    <Box sx={{ ...commonStyles, border: 1 }}>
      <Box display="flex" alignItems="center">
        <ListItemIcon>
          <LockClock fontSize="large" sx={{ m: "1.5rem 0rem 1.5rem 1.5rem" }} />
        </ListItemIcon>
        <Box sx={{ m: "1.5rem 1.5rem 1.5rem 1.5rem" }}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="h3" color={theme.palette.secondary[100]} fontWeight="bold">
                Business Hours
            </Typography>
          </Box>
          <Typography variant="h5" color={theme.palette.secondary[300]}>
            Enter the time the building is open.
          </Typography>
        </Box>
        <Box ml="auto">
          {!showConf && (
            <Button
              variant="contained"
              onClick={toggleShowConf}
              sx={{
                backgroundColor: theme.palette.secondary.light,
                color: theme.palette.background.alt,
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                m: "0rem 1rem 0rem 0rem",
                "&:hover": { color: theme.palette.secondary.dark },
              }}
            >
              EDIT
            </Button>
          )}
          {showConf && (
            <Button
              variant="contained"
              onClick={toggleShowConf}
              sx={{
                backgroundColor: theme.palette.secondary.light,
                color: theme.palette.background.alt,
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                m: "0rem 1rem 0rem 0rem",
                "&:hover": { color: theme.palette.secondary.dark },
              }}
            >
              <Close />
            </Button>
          )}
        </Box>
      </Box>

      {showConf && (
        <Box sx={{ padding: "20px" }}>
          <Paper
            elevation={3}
            style={{
              padding: 20,
              backgroundColor: theme.palette.background.alt,
              backgroundImage: "none",
              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
            }}
            sx={{ "& > div": { gridColumn: isNonMobile ? undefined : "span 4" } }}
          >
            <Grid container spacing={2}>
              {days.map(({ key, label }) => (
                <React.Fragment key={key}>
                  <Grid item xs={4}>
                    <Typography>{label}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TimePicker
                      label="Open"
                      value={toDayjs(parseBusinessHours(businessHours)[`${key}M`])}
                      onChange={(value) =>
                        handleChange(key, "M", to12hString(value))
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TimePicker
                      label="Close"
                      value={toDayjs(parseBusinessHours(businessHours)[`${key}E`])}
                      onChange={(value) =>
                        handleChange(key, "E", to12hString(value))
                      }
                    />
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Paper>

          <Button
            startIcon={<Save />}
            onClick={handleFormSubmit}
            sx={{
              p: "1rem",
              m: "1rem 0rem 0rem 0rem",
              backgroundColor: palette.secondary.light,
              color: palette.background.alt,
              fontWeight: "bold",
              "&:hover": {
                color: palette.primary.main,
                backgroundColor: palette.secondary.dark,
                fontWeight: "bold",
              },
            }}
          >
            Save
          </Button>
        </Box>
      )}
    </Box>
  );
};

// Convert "8:00 AM" => "08:00"
const convertTo24h = (time12h) => {
  if (!time12h) return "";
  const [time, modifier] = time12h.split(" ");
  let [hours, minutes] = time.split(":");
  if (modifier === "PM" && hours !== "12") hours = parseInt(hours, 10) + 12;
  if (modifier === "AM" && hours === "12") hours = "00";
  return `${hours.toString().padStart(2, "0")}:${minutes}`;
};

// Convert "08:00" => "8:00 AM"
const convertTo12h = (time24h) => {
  if (!time24h) return "";
  let [hours, minutes] = time24h.split(":").map(Number);
  const modifier = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12;
  return `${hours}:${minutes.toString().padStart(2, "0")} ${modifier}`;
};

export default LocationBusinessHoursConfig;
