import React, { useEffect, useState } from "react";
import { Box, Link, Typography, useTheme } from "@mui/material";
import Header from "components/Header";
import { useDispatch, useSelector } from "react-redux";
import { hideLoading, showLoading } from "state/loadingSlicer";
import DialogYesNo from "components/dialog/DialogYesNo";
import {  useNavigate } from "react-router-dom";
import tm from "components/TranslationManager";
import { deleteDeniedPerson } from "hooks/HttpRequests";
import { useFrontDeskManager } from "components/FrontDeskManager";
import { enqueueSnackbar } from "notistack";
import SadevioDataGridPro from "components/SadevioDataGridPro";
import GridCellActions from "components/GridCellActions";
import { Android, Apple, Power } from "@mui/icons-material";
import { sadevioColors } from "components/Colors";
// Import images for different types (if inside src/assets/)


const Devices = () => {
  var isAuth = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { convertToFrontDeskTimeZone, convertToFrontDeskTimeZoneDateTimeString } = useFrontDeskManager();

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [yesNoMessage, setYesNoMessage] = useState("");
  const [yesNoDialogEntity, setYesNoDialogEntity] = useState({});

  const [topSearchFilter, setTopSearchFilter] = useState("");

  const theme = useTheme();

  /////////////// GRID /////////////
  // values to be sent to the backend

  const [data, setData] = useState({ data: [], total_count: 0 });
  const [gridLoading, setGridLoading] = useState(false);
  const [triggerRefresh, setTriggerRefresh] = useState(false);

  const [search, setSearch] = useState("");

  /////////////// USE EFFECT /////////////


  useEffect(() => {
    // This code will run every time paginationModel changes
    setTriggerRefresh(!triggerRefresh)

  }, []);


  const handleOptionCellActionClick = (id, action, row) => {
    if (action === 'edit') {
      // Handle edit action
      console.log('Edit record:', row);
      navigate(`/global/devices/${row.entityId}`)
    } else if (action === 'delete') {
      // Handle delete action
      // console.log('Delete record:', row);
      setConfirmationOpen(true);
      setYesNoMessage("Do you really want to delete this entity?")
      row["action"] = "delete";
      setYesNoDialogEntity(row)
      
    }
  };

  const columns = [
    
    {
      field: "entityId",
      headerName: "Entity Id",
      filterable: false,
      type: 'number'
    },
    {
      field: 'active',
      headerName: 'active',
      width: 100,
      type: 'boolean'
    },
    {
      field: "type",
      headerName: "User Type",
      renderCell: (params) => {

        var imagePath = `${process.env.PUBLIC_URL}/kiosks/kiosk_v_2_2.svg`;
    
        if(params.value == "kiosk 1_1"){
          imagePath = `${process.env.PUBLIC_URL}/kiosks/kiosk_v_1_1.svg`;
        }else if(params.value == "kiosk 2_1"){
          imagePath = `${process.env.PUBLIC_URL}/kiosks/kiosk_v_2_1.svg`;
        }else if(params.value == "kiosk 2_2"){
          imagePath = `${process.env.PUBLIC_URL}/kiosks/kiosk_v_2_2.svg`;
        }else if(params.value == "kiosk tablet"){
          return <Android style={{ fontSize: 40, color: theme.palette.secondary[100] }} />;
        }else if(params.value == "iOs Kiosk"){
          return <Apple style={{ fontSize: 40, color: theme.palette.secondary[100] }} />;
        }
         
        return (
          <img
            src={imagePath}
            onError={(e) => {
              console.error("Image failed to load:", e.target.src);
              e.target.style.display = "none"; // Hide broken images
            }}
            alt={params.value}
            style={{ width: "30px", height: "80px", borderRadius: "5px" }}
          />
        );
      },
    },
    {
        field: "deviceId",
        headerName: "Device ID",
        flex: 1,
        renderCell: (params) => (
          <Link
            style={{ cursor: 'pointer', fontWeight: "bold", fontSize: '18px' }}
            onClick={() => {
              navigate(`/global/devices/${params.row.entityId}`)
            }}
          >
          {params.row.deviceId}
        </Link>
        ),
      },
      {
        field: "deviceName",
        headerName: "Device Name",
        flex: 1,
        renderCell: (params) => (
          <Link
            style={{ cursor: 'pointer', fontWeight: "bold", fontSize: '18px' }}
            onClick={() => {
              navigate(`/global/devices/${params.row.entityId}`)
            }}
          >
          {params.row.deviceName}
        </Link>
        ),
      },
    {
      field: 'model',
      filterable: true,
      headerName: 'Model',
    },
    {
      field: 'version',
      filterable: true,
      headerName: 'Version',
    },
    {
      field: 'frontDesk',
      filterable: true,
      flex:1,
      headerName: 'Front Desk',
      renderCell: (params) => {
        
        if(params.value == null){
          return (
            <span
              style={{
                color: 'red' ,
                fontWeight: 'bold',
              }}
            >
              {"not assigned"}
            </span>
          );
          
        }

        return <span>{params.value.fullName}</span>;
      }
    },
    {
      field: "lastAlive",
      headerName: "Alive",
      width: 150,
      renderCell: (params) => {

        var currentDate = Date.UTC(
          new Date().getUTCFullYear(),
          new Date().getUTCMonth(),
          new Date().getUTCDate(),
          new Date().getUTCHours(),
          new Date().getUTCMinutes(),
          new Date().getUTCSeconds(),
          new Date().getUTCMilliseconds()
        );

        var fifteenSecondsAgo = currentDate - 15 * 1000; // 15 seconds * 1000 milliseconds per second
        var oneMinuteAgo = currentDate - 60 * 1000; // 60 seconds * 1000 milliseconds per second

        if(params.value == null){
            return <Power style={{ fontSize: 40, color: sadevioColors.hexColorKioskGray }} />;
        }else if(params.value >= fifteenSecondsAgo){
            return <Power style={{ fontSize: 40, color: sadevioColors.hexColorKioskGreen }} />;
        }else if(params.value >= oneMinuteAgo){
            return <Power style={{ fontSize: 40, color: sadevioColors.hexColorKioskOrange }} />;
        }else{
            return <Power style={{ fontSize: 40, color: sadevioColors.hexColorKioskRed }} />;
        }
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      filterable: false,
      width: 80,
      sortable: false,
      renderCell: (params) => (
        <GridCellActions
          id={params.row.entityId}
          row={params.row}
          onOptionClick={handleOptionCellActionClick}
        />
      ),
    },
  ];

  const handleExecute = async (values) => {
  
  
        setConfirmationOpen(false)
    
        dispatch(showLoading(""))
    
        if(values.action == "delete"){
  
          deleteDeniedPerson(navigate)(values.entityId)
            .then((result) => {
            // Use the result.data as needed      
            dispatch(hideLoading());
                if(result.success){
                    
                  enqueueSnackbar({
                    message: "Was deleted successfully",
                      variant: 'success',
                      anchorOrigin: {
                        vertical: 'top', // 'top', 'bottom'
                        horizontal: 'center', // 'left', 'center', 'right'
                      },
                      autoHideDuration: 3000, 
                      // persist: true
                    
                  });
    
                  setTriggerRefresh(!triggerRefresh);
    
                }else{
  
  
  
                  enqueueSnackbar({
                    message: "Something went wrong during request",
                      variant: 'error',
                      anchorOrigin: {
                        vertical: 'top', // 'top', 'bottom'
                        horizontal: 'center', // 'left', 'center', 'right'
                      },
                      autoHideDuration: 3000, 
                      // persist: true
                    
                  });
                }
    
    
          })
          .catch((error) => {
            dispatch(hideLoading());
    
            if (typeof error.action !== "undefined") {
              if (error.action == "relogin") {
                navigate("/");
              }else if(error.action == "force_delete"){
                  console.log("force delete")
                  setConfirmationOpen(true);
                  setYesNoMessage("This entity has still referecnes to visits. Do you want to force the delete?")
                  yesNoDialogEntity["action"] = "force_delete";
                  setYesNoDialogEntity(yesNoDialogEntity)
              }
            }else if (error.hasOwnProperty('success')) {
              enqueueSnackbar({
                message: "Error "+error.message,
                  variant: 'error',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
                
              });
            }
    
          });
        }
    
          
    
      };


      const rowTemplateMobile = (row) => {
        const currentDate = Date.UTC(
          new Date().getUTCFullYear(),
          new Date().getUTCMonth(),
          new Date().getUTCDate(),
          new Date().getUTCHours(),
          new Date().getUTCMinutes(),
          new Date().getUTCSeconds(),
          new Date().getUTCMilliseconds()
        );
      
        const fifteenSecondsAgo = currentDate - 15 * 1000; // 15 seconds ago
        const oneMinuteAgo = currentDate - 60 * 1000; // 1 minute ago
      
        let powerIconColor;
        if (row.lastAlive == null) {
          powerIconColor = sadevioColors.hexColorKioskGray;
        } else if (row.lastAlive >= fifteenSecondsAgo) {
          powerIconColor = sadevioColors.hexColorKioskGreen;
        } else if (row.lastAlive >= oneMinuteAgo) {
          powerIconColor = sadevioColors.hexColorKioskOrange;
        } else {
          powerIconColor = sadevioColors.hexColorKioskRed;
        }
      
        return (
          <Box 
            display="flex" 
            alignItems="center" 
            justifyContent="space-between"
            padding="8px"
            borderRadius="8px"
          >
            {/* Left Side - Device Info */}
            <Box>
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                Device Name - {row.deviceName}
              </Typography>
      
              <Typography variant="body2" style={{ color: row.active ? "green" : "red" }}>
                {row.active ? "Active" : "Inactive"}
              </Typography>
      
              <Typography variant="caption">
                Version: {row.version} • Model: {row.model}
              </Typography>
            </Box>
      
            {/* Right Side - Power Icon */}
            <Power style={{ fontSize: 40, color: powerIconColor }} />
          </Box>
        );
      };
    
      
      // Define row click event behavior
      const handleRowClick = (row) => {

        navigate(`/global/devices/${row.entityId}`)

      };


  const btnNewVisit = tm.translate('btnNewDeniedPerson', 'New Denied Person');
  
  const buttons = [
    // // { type:'search',label: "Search employees..." , onChange: handleSearchInputChange, iconName: ''},
    // { type:'button',label: btnNewVisit , onClick: () => {
    //           // openDrawer()
    //           navigate("/visit/settings/deniedperson/new") 
    //           // setAddEmployeeOpen(true);
    //         }, iconName: '', btnId: 'btnNewDeniedPerson'},

  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Devices" subtitle="List of all of your devices" elements={buttons} />
      <DialogYesNo
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={handleExecute}
        theme={theme}
        message={yesNoMessage}
        entity={yesNoDialogEntity}
      />
      <SadevioDataGridPro columns ={columns} url={'device'} triggerRefresh= {triggerRefresh} mobileSupport={true} rowTemplateMobile={rowTemplateMobile} rowClickEvent={handleRowClick}/>
    </Box>
  );
};

export default Devices;