import React, { useEffect, useState } from "react";
import { Box, Button,  ListItemIcon, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Close, Rsvp, Save } from "@mui/icons-material";
import {  getSilentAlarmConfig } from "hooks/HttpRequests";
import * as yup from "yup";
import { Formik } from "formik";
import { Oval } from "react-loader-spinner";
import { useFrontDeskManager } from "components/FrontDeskManager";
import { useDispatch } from "react-redux";
import { useIntegrationConfig } from "components/IntegrationConfigManager";

const VisitorEmailInvitationConfig = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  const { palette } = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { selectedFrontDesk } = useFrontDeskManager();
  const { setShowSilentAlarm } = useIntegrationConfig();

  const initialValuesRegister = {
    phoneNumbers: "",
    emailAddresses: "",
    active: false,
  };

  const [loadedFormData, setLoadedFormData] = useState({ initialValuesRegister });
  const [loading, setLoading] = useState(false);
  const [showConf, setShowConf] = useState(false);

  useEffect(() => {
    if (showConf) {
      loadData();
    }
  }, [showConf]);

  const registerSchema = yup.object().shape({
    // usersToNotify: yup.string().required("required"),
  });

  

  const toggleShowConf = () => {
    setShowConf(!showConf);
  };

  const commonStyles = {
    bgcolor: theme.palette.primary[600],
    m: "1rem 0rem 1rem 0rem",
    borderRadius: 1,
    borderColor: theme.palette.secondary[100],
  };

  const loadData = async () => {
    setLoading(true);

    getSilentAlarmConfig(navigate)(selectedFrontDesk.entityId)
      .then((result) => {
        if (result.success) {
          setLoadedFormData(result.data)
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleFormSubmit = (values) => {

    var submitData = {};
    submitData = values;

    // dispatch(showLoading(""))

    // putSilentAlarm(navigate)(selectedFrontDesk.entityId, submitData)
    //   .then((result) => {
    //     if (result.success) {
    //       setLoadedFormData(result.data);
    //       toggleShowConf()
    //       setShowSilentAlarm(result.data.active)
    //     }
    //     dispatch(hideLoading());
    //   })
    //   .catch((error) => {
        
    //     dispatch(hideLoading());
    //   });
  };

  return (
    <Box sx={{ ...commonStyles, border: 1 }}>
      <Box display="flex" alignItems="center">
        <ListItemIcon>
          <Rsvp fontSize="large" sx={{ m: "1.5rem 0rem 1.5rem 1.5rem" }} />
        </ListItemIcon>
        <Box sx={{ m: "1.5rem 1.5rem 1.5rem 1.5rem" }}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="h3" color={theme.palette.secondary[100]} fontWeight="bold">
              Invitation Email
            </Typography>
          </Box>
          <Typography variant="h5" color={theme.palette.secondary[300]}>
              Customize the invitation email.
          </Typography>
        </Box>
        <Box ml="auto">
          {!showConf && (
            <Button
              variant="contained"
              onClick={toggleShowConf}
              sx={{
                backgroundColor: theme.palette.secondary.light,
                color: theme.palette.background.alt,
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                m: "0rem 1rem 0rem 0rem",
                "&:hover": { color: theme.palette.secondary.dark },
              }}
            >
              EDIT
            </Button>
          )}
          {showConf && (
            <Button
              variant="contained"
              onClick={toggleShowConf}
              sx={{
                backgroundColor: theme.palette.secondary.light,
                color: theme.palette.background.alt,
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                m: "0rem 1rem 0rem 0rem",
                "&:hover": { color: theme.palette.secondary.dark },
              }}
            >
              <Close />
            </Button>
          )}
        </Box>
      </Box>

      {showConf && (
        <Box>
          {loading ? (
            <Box sx={{ width: "100%", height: "100%", padding: "20px", display: "flex", justifyContent: "center" }}>
            <Oval
                visible={true}
                height="80"
                width="80"
                color="#ffedc2"
                secondaryColor="#ffedc2"
                ariaLabel="oval-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </Box>
          ) : (
            <Box sx={{ padding: "20px"}}>
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={loadedFormData || initialValuesRegister}
                validationSchema={registerSchema}
                enableReinitialize={true}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  resetForm,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Paper
                      elevation={3}
                      style={{
                        padding: 20,
                        backgroundColor: theme.palette.background.alt,
                        backgroundImage: "none",
                        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
                      }}
                      sx={{ "& > div": { gridColumn: isNonMobile ? undefined : "span 4" } }}
                    >
                      Coming soon

           
                          
                    </Paper>
                    
                        <Button
                          startIcon={<Save />}
                          disabled
                          type="submit"
                          sx={{
                            p: "1rem",
                            m: "1rem 0rem 0rem 0rem",
                            backgroundColor: palette.secondary.light,
                            color: palette.background.alt,
                            fontWeight: "bold",
                            "&:hover": {
                              color: palette.primary.main,
                              "&:hover": { color: theme.palette.secondary.dark },
                              fontWeight: "bold",
                            },
                          }}
                        >
                          Save
                        </Button>
                  </form>
                )}
              </Formik>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default VisitorEmailInvitationConfig;
