import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Card, CardContent, Typography, Avatar, Grid, Chip, Divider, CardActionArea, useTheme } from "@mui/material";
import { Event } from "@mui/icons-material";
import { getSessionToken, getUri } from "hooks/HttpRequests";
import { stringAvatar } from "components/StyledComponents";
import { useFrontDeskManager } from "components/FrontDeskManager";

const VisitorStatistics = ({ upcomingVisits, title }) => {
  const navigate = useNavigate();
  const { allAvailableFrontDesks, convertToTimeZoneDateTimeString } = useFrontDeskManager();
  const theme = useTheme();

  // Handle empty state properly
  if (!upcomingVisits || !Array.isArray(upcomingVisits) || upcomingVisits.length === 0) {
    return (
      <Box textAlign="center" p={2}>
        <Typography variant="h6" color="text.secondary">
          {title ? `${title}: No records found` : "No visits available."}
        </Typography>
      </Box>
    );
  }

  return (
    <Box gridColumn="span 6" gridRow="span 3">
      <Grid container spacing={2}>
        {upcomingVisits.map((visit) => {
          // Find the matching front desk
          const frontDesk = allAvailableFrontDesks.find((desk) => desk.entityId === visit.frontDeskEntityId);
          const frontDeskName = frontDesk ? frontDesk.name : "Unknown Location";

          return (
            <Grid item xs={12} sm={6} md={6} key={visit.visitId}>
              <Card elevation={3} sx={{ borderRadius: 2, p: 1, borderColor: 'white', backgroundColor: theme.palette.background.alt, }}>
                {/* Clickable Card */}
                <CardActionArea onClick={() => navigate(`/visit/${visit.visitId}`)}>
                  <CardContent>
                    {/* Front Desk Location */}
                    <Typography variant="h7" fontWeight="bold">
                      {frontDeskName}
                    </Typography>

                    {/* Status */}
                    <Typography variant="body2" color="text.secondary" sx={{ mt: 2} }>
                      <Chip
                        label={visit.status ? visit.status.replace("_", " ").toUpperCase() : "Unknown"}
                        color={
                          visit.status === "pre_registered"
                            ? "secondary"
                            : visit.status === "finished"
                            ? "error"
                            : "success"
                        }
                        size="small"
                      />
                    </Typography>

                    {/* Date Range */}
                    {/* Date Range - Two Rows with Icon in Front */}
                    <Box display="flex" alignItems="center" gap={1} mt={2}>
                      <Event color="secondary" sx={{ alignSelf: "start", mt: 0.5 }} />
                      <Box>
                        <Typography variant="body2">
                          <strong>Start:</strong> {visit.validFrom ? convertToTimeZoneDateTimeString(visit.validFrom, frontDesk) : "N/A"}
                        </Typography>
                        <Typography variant="body2">
                          <strong>End:</strong> {visit.validTo ? convertToTimeZoneDateTimeString(visit.validTo, frontDesk) : "N/A"}
                        </Typography>
                      </Box>
                    </Box>

                    <Divider sx={{ my: 1 }} />

                    {/* Visitors Section */}
                    {visit.visitors && visit.visitors.length > 0 ? (
                      visit.visitors.map((visitor) => (
                        <Box key={visitor.visitorId} display="flex" alignItems="center" gap={2} mb={1}>
                          {visitor.picturePath ? (
                            <Avatar
                              {...stringAvatar(visitor.fullName)}
                              alt={visitor.fullName}
                              src={`${getUri(getSessionToken())}/visitor/${visitor.visitorId}/picture/${visitor.picturePath}`}
                            />
                          ) : (
                            <Avatar {...stringAvatar(visitor.fullName)} />
                          )}
                          <Box>
                            <Typography variant="body1" fontWeight="bold">
                              {visitor.fullName || "Unknown Visitor"}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              {visitor.companyName || "No Company"}
                            </Typography>
                          </Box>
                        </Box>
                      ))
                    ) : (
                      <Typography variant="body2" color="text.secondary">
                        No visitors assigned.
                      </Typography>
                    )}

                    {/* Hosts Section */}
                    <Divider sx={{ my: 1 }} />
                    {visit.hosts && visit.hosts.length > 0 ? (
                      visit.hosts.map((host) => (
                        <Box key={host.entityId} display="flex" alignItems="center" gap={2}>
                          {host.picturePath ? (
                            <Avatar
                              {...stringAvatar(host.fullName)}
                              alt={host.fullName}
                              src={`${getUri(getSessionToken())}/host/${host.entityId}/picture/${host.picturePath}`}
                            />
                          ) : (
                            <Avatar {...stringAvatar(host.fullName)} />
                          )}
                          <Box>
                            <Typography variant="body1" fontWeight="bold">
                              {host.fullName || "Unknown Host"}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              {host.email || "No Email"}
                            </Typography>
                          </Box>
                        </Box>
                      ))
                    ) : (
                      <Typography variant="body2" color="text.secondary">
                        No hosts assigned.
                      </Typography>
                    )}
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default VisitorStatistics;
